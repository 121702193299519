a.button, button.button {
  color: $generalFontColor;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.16em;
  font-weight: 800;
  text-align: center;
  padding: 1.1rem 3rem;
  transform: scale(1,1);
  transition: all 0.5s ease-in-out;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);
  box-shadow: 0 0 4px -12px rgba(0,0,0,0.9);
  &.button-primary {
    color: $primaryColorReverse;
    background-color: $buttonPrimaryBackground;
    border-radius: 128px;
    //padding: 1.8rem 3rem;
    text-decoration: none;
    letter-spacing: 0.02em;
    line-height: 1.25em;
    &:hover {
      background-color: $buttonPrimaryBackgroundHover;
    }
  }
  &.button-secondary {
    color: $darkColorBackground !important;
    background-color: $primaryColorReverse;
    //font-size: 1.5rem;
    //padding: 1.1em 2.3em;
    border-radius: 128px;
    line-height: 1.25em;
    &:hover {
      background-color: lighten($primaryColorReverse, 5%);
    }
    @media only screen and (min-width: 1024px) {
      //font-size: 1.6rem;
      //padding: 1em 2em;
    }
  }
  &.button-dark {
    color: white !important;
    background-color: $darkColorBackground;
    //padding: 1.1em 2.3em;
    border-radius: 128px;
    line-height: 1.25em;
    &:hover {
      background-color: lighten($darkColorBackground, 5%);
    }
  }
  &.button-mobile {
    color: $WhiteBackground !important;
    background-color: inherit;
    font-size: 1.6rem;
    padding: 0.8em 2.5em;
    width: 100%;
    height: 100%;
    line-height: 1em;
    position: relative;
    display: contents;
    &:hover {
      background-color: inherit;
      color: black !important;
    }
  }
  &:hover {
    transform: scale(1.05,1.05);
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
    box-shadow: 0 17px 10px -13px rgba(0,0,0,.5);
  }
  &.button-block {
    display: block;
  }
}
