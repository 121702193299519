section.section.section-info {
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_splash_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 40vh;

  .buttonWrapper {
    margin-top: 4em;
  }

  h1 {

  }

  h2 {

  }

  h1, h2, h3, h4, p {
    color: white;
  }

  .aboutSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    h4 {
      font-size: 1.9em;
      margin-bottom: 8px;
      font-weight: 700;
    }

    .aboutCards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      flex-basis: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;
      width: 100%;

      .aboutBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 50%;
        width: 100%;
        position: relative;
        border-radius: 0 60px 0 28px;
        background-image: linear-gradient(157deg, #860300 0%, #BA2725 100%);
        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 10px 40px;
        overflow: hidden;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          background-image: url("/assets/img/bottom_right_flame_wh.svg");
          background-repeat: no-repeat;
          background-position: bottom right;
          background-size: 100% auto;
          width: 30%;
          height: 100%;
          overflow: hidden;
          z-index: 0;
        }

        .pict {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: auto;
          overflow: hidden;

          img {
            height: 100%;
            width: auto;
          }
        }

        .desc {
          color: white;
          position: absolute;
          top: 0;
          right: 0;
          width: 67%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          padding: 10px;

          .name {
            font-weight: 800;
            font-size: 1.1em;
          }

          .numbers, a.numbers {
            color: white;
            text-decoration: none;
            font-weight: 400;
            font-size: 0.8em;
          }

          .numbers a {
            color: white;
            text-decoration: none;
            font-weight: 400;
            font-size: 1em;
          }
          @media only screen and (min-width: $mobileBreakpoint) {
            .name {
              font-size: 1.35em;
            }

            .numbers, a.numbers {
              font-size: 0.9em;
            }
          }
        }
      }
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      flex-direction: row;

      .aboutCards {
        flex-basis: 50%;
        padding: 50px;

        .desc {

          span.name {
            font-size: 1.7em;
          }
        }
      }
    }
  }

  #partenaires {
    padding-top: 7em;

    img {
      width: 100%;
      height: auto;
    }
  }
}
