
// Couleurs
$primaryColor: #D7282F; // orange
$primaryColorReverse: #F2F2F2; // blanc cassé
$darkColorBackground: #3b3d36; // Gris foncé
$colorBackground: #8e2426; // bleu-vert
$LightBackground: #f8f8f8; // gris pale
$WhiteBackground: #ffffff; // blanc

// Fontes
$titleFontFamily: 'Montserrat', Helvetica, sans-serif;
$generalFontFamily: $titleFontFamily;
$generalFontColor: black;
$textShadow: 0 0 7px rgba(12, 12, 12, 0.58);

// $Header
$headerBackground: white;
$headerHeight: 70px;
$headerHeightMobile: 0;

// button
$buttonTextColor: black;
$buttonPrimaryBackground: rgb(186, 39, 37);
$buttonPrimaryBackgroundHover: rgb(149, 39, 37);
$buttonSecondaryBackground: $primaryColor;

// form
$inputPlaceholder: #3b3c3c;

// $Footer
$footerBackground: $darkColorBackground;
$footerFontColor: $WhiteBackground;
$ctaFooterBackground: $primaryColor;
$ctaFooterHeight: 60px;

// #LightboxCTA
$lightboxCTAbackground: rgba(186, 39, 37, 0.97);
$lightboxPFbackground: rgba(73, 73, 73, 0.97);

// Sections
// - Secion punch
$sectionPunchOverlayBackground: rgba(0, 0, 0, 0.29);

// Mobile breakooint
$mobileBreakpoint: 700px;
