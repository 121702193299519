section.section.section-features {
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;

  #values_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin: .5rem 0;
    margin-bottom: 70px;

    .value {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      flex-basis: 45%;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 5px;
      width: 100%;
      transform: scale(1,1);
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);

      img {
        width: 100%;
        height: auto;
      }

      &:hover {
        transform: scale(1.05,1.05);
        transition: all 0.5s ease-in-out;
        transition-timing-function: cubic-bezier(3, 1, 2, 7);
      }
      @media only screen and (min-width: $mobileBreakpoint) {
        flex-direction: column;
        flex-basis: 22%;
        margin: 10px;
      }
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      flex-direction: row;
      margin: .5rem 2%;
    }
    @media only screen and (min-width: 1200px) {
      flex-direction: row;

      .value {
      }
    }
  }

  @media only screen and (min-width: 1140px) {
    .productpict img {
      width: 100%;
    }
  }
}

i.fa {
  font-size: 3em;
}

.icon {
  transition: all .5s .3s ease-in-out;
  width: 75px;

  &.horiz {
    float: left;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: $mobileBreakpoint) {
}
