.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 60px 20px;
  margin-bottom: $ctaFooterHeight;
  color: white;
  background-image: linear-gradient(180deg, #950604 0%, #ba2725 100%);

  h4 {
    margin-bottom: 0;

    a {
      color: #fff;
      text-decoration: none;
      cursor: default;
    }
  }

  p {
    margin-top: 5px;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: 0 0 30px 0;

    .address {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      .indivAddress {
        margin: 15px 25px;

        p {
          font-size: 12px;
        }

        span {
          &.city {
            color: black;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
          }
          &.phone {
            color: white;
            font-size: 18px;
            font-weight: 800;
            line-height: 0.5em;
          }
        }
      }
    }
    .logo {
      float: left;
      width: 50%;
      margin-top: 4px;
      img {
        height: 28px;
      }
    }
    .socialfooter {
      width: 50%;
      float: left;
      text-align: right;
      a,
      a img {
        color: #fff;
        fill: #fff;
        width: 30px;
        height: 30px;
        margin-left: 3px;
      }
    }

    a {
      margin: 8px 0;
      display: block;
      color: white;
      text-decoration: none;
      font-weight: 600;
    }
  }
  small {
    font-weight: 300;
    font-size: 9px;
  }
  @media only screen and (min-width: $mobileBreakpoint) {
    padding: 20px 30px;
    margin-bottom: -30px;
    padding-bottom: 15em;

    .contacts {
      .address {
        flex-direction: row;
      }
    }
  }
}

.cta-footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  height: $ctaFooterHeight;
  font-weight: 400;
  padding-top: 20px;
  width: 100%;
  background-color: $ctaFooterBackground;
  text-align: center;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.7);
  @media only screen and (min-width: $mobileBreakpoint) {
    display: none;
  }
}

.scroll-top {
  width: 75px;
  height: 75px;
  position: fixed;
  bottom: 35px;
  right: -20px;
  opacity: 0;
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);

  @media only screen and (min-width: $mobileBreakpoint) {
    bottom: 25px;
    right: 20px;
  }
}
.scroll-top i {
  display: inline-block;
  color: #fff;
}
