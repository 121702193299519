.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  top:0;
  z-index: 11;
  width:100%;
  padding: 20px;
  background-color: rgba(0,0,0,0);
  transition: background-color .25s ease-in-out;
  .logo {
    text-align: center;
    img{
      height: 28px;
      @media only screen and (min-width: 1024px) {
        height: 30px;
        margin-top: 7px;
      }
    }
  }
  .logocouleur, .logoblanc {
    position: absolute;
    top: 12px;
    left: 20px;
    @media only screen and (min-width: 1024px) {
      top: 9px;
      left: 20px;
    }
  }
  .logocouleur {
    //display: none;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
  }
  .logoblanc {
    //display: block;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
  }
  &.fixed {
    background-color: rgba(0,0,0,0.7);
    .logocouleur {
      //display: block;
      opacity: 1;
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
    }
    .logoblanc {
      //display: none;
      opacity: 1;
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
    }
  }
  .button-cta{
    display: none;
  }
  @media only screen and (min-width:1024px) {
    justify-content: space-between;
    height: $headerHeight;
    padding: 20px 30px;
    .logo {
      float:left;
      img{
        height: 38px;
      }
    }
    .button-cta{
      display: inherit;
      float: right;
    }
  }
  img.logomobile {
    margin: 8px 0 25px 0;
  }
  @media only screen and (min-width:$mobileBreakpoint) {
    background-color: rgba(0, 0, 0, 0);

    &.fixed {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
