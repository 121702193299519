section.section.section-portfolio {
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;
  padding-bottom: 70px;

  a.boxfit {
    background-color: transparent;
    list-style-type: none;
    display: flex;
    position: relative;
    width: 46%;
    margin: 5px;
    padding: 10px;
    border: 1px solid #ffffff;
    text-decoration: none;
    text-align: left;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    float: left;
    color: $WhiteBackground;
    z-index: 0;

    .borderToTitle {
      border-left: 6px solid #3b3e37;
      border-right: 6px solid #3b3e37;
      left: -1px;
      padding-left: 9px;
      position: absolute;
      z-index: 3;
      width: 101.5%;
    }

    span {
      display: block;
      position: relative;
      float: left;

      &.aboutReal {
        text-transform: uppercase;
        width: 100%;
        color: $WhiteBackground;
        font-size: 75%;
        font-weight: 300;
      }
      &.title {
        width: 100%;
        color: $WhiteBackground;
        font-size: 115%;
        font-weight: 600;
      }
      &.technologies {
        display: none;
        float: left;
        position: absolute;
        left: 8px;
        bottom: 4px;
        img, object, svg {
          height: 18px;
          margin-right: 2px;
        }
        .st1 {
          fill: none;
        }
        .st0 {
          fill: $WhiteBackground;
        }
      }
      &.moreInfo {
        width: 30px;
        height: 30px;
        position: absolute;
        right: -1px;
        bottom: -1px;
        overflow: hidden;
        z-index: 2;

        span {
          font-size: 15px;
          font-weight: 700;
          position: absolute;
          right: 5px;
          bottom: -1px;
          color: $darkColorBackground;
        }

        &:before {
          content: '';
          position: absolute;
          left: 20%;
          width: 100%;
          height: 200%;
          background-color: rgb(255, 255, 255); /* fallback */
          background-color: rgba(255, 255, 255, 1);
          top: 0;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    @media only screen and (min-width: 768px) {
      width: 23%;
    }

    ::selection {
      background: transparent;
      color: rgba(255, 255, 255, 0.7);
    }
    section.section.section-portfolio ul.boxtofit li.boxfit ::selection {
      color: #ffffff;
    }

    &:hover {
      //background-color: #ffffff;
      transition: all .2s ease-in-out .2s;
      transition: background-color .07s ease-in-out .07s;

      &.dermago {
        background-color: #3ab5ba;
      }
      &.caronetguay {
        background-color: #02a2ec;
      }
      &.podboxx {
        background-color: #e31f13;
      }
      &.pret180 {
        background-color: #fccc01;
      }
      &.jefffillion {
        background-color: #ff6a3c;
      }
      &.sinisco {
        background-color: #fff200;
      }
      &.radiopirate {
        background-color: #42a1bf;
      }
      &.radioego {
        background-color: #ff702d;
      }
      &.annebelanger {
        background-color: #a5924d;
      }
      &.artizyou {
        background-color: #f26e28;
      }
      &.authentique {
        background-color: #9aaea9;
      }
      &.accuvyd {
        background-color: #fcb985;
      }
      &.patchtest {
        background-color: #36baa6;
      }
      &.accesm {
        background-color: #468eba;
      }

      span {

        &.aboutReal {
          color: $darkColorBackground;
        }
        &.title {
          color: $darkColorBackground;
        }
        &.technologies {
          display: none;
          .st0 {
            fill: $darkColorBackground;
          }
        }
        &.moreInfo {
          width: 40px;
          height: 40px;
          span {
            color: $WhiteBackground;
            font-size: 20px;
            bottom: -2px;
          }

          &:before {
            background-color: $darkColorBackground;
          }
        }
      }

      .icons {
        fill: $darkColorBackground;
      }
    }
    .icons {
      fill: $WhiteBackground;
    }
  }

  @media only screen and (max-width: $mobileBreakpoint) {
    a.boxfit {
      span.title {
        font-size: 100%;
      }
      span.aboutReal {
        font-size: 65%;
      }
    }
  }
  @media only screen and (min-width: $mobileBreakpoint) {
    i.fa {
      font-size: 4em;
    }
    .description {
      max-width: 300px;
      margin-top: 0.4em;
      margin-left: 1em;
      font-size: 1.3em;
    }
  }
}
