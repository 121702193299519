section.section.section-punch {
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 4em 0 10em 0;
  @media only screen and (min-width: 480px) {
    @include sectionFlex();
    margin-top: -100px;
    padding: 4em 0 10em 0 !important;
  }

  h1 {
    color: white;
  }

  h2 {
    font-size: 1.9em;
    margin-bottom: 8px;
    span {
      color: $primaryColor;
    }
  }

  h1, h2 {
    color: white !important;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 1024px) {

    }
  }

  h4 {

  }

  h1, h2, h3, h4 {
    text-align: center;

    @media only screen and (min-width: 700px) {
      text-align: left;
    }
  }

  p {
    text-align: center;
    color: $primaryColorReverse;

    @media only screen and (min-width: 700px) {
      text-align: left;
    }
  }

  .button-primary {
    margin: 12px 0;
  }

  .main_logo {

    img {
      margin-top: 30px;
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      img {
        margin-top: inherit;
        width: 60%;
      }
    }
  }

  //dotted punch
  .maintag {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;

    img {
      width: 100%;
      margin: 16px 0;
    }
    @media only screen and (min-width: 1024px) {
      margin: 20px;
    }
    @media only screen and (min-width: 700px) {
    }
  }

  .flamingBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 100%;
    background-image: linear-gradient(0deg, #000000 0%, #1c1c1c 100%);
    position: relative;
    //border-radius: 0 60px 0 28px;
    border: 2px solid $primaryColor;
    transform: scale(1, 1);
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
    clip-path: polygon(0 0, 72.6% 0,100% 26.5%,100% 100%,27% 100%,0 73.4%);
    margin: 5px 0;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: -1px;
      background-image: url("/assets/img/bottom_right_rooftop.svg");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 100% auto;
      width: 60%;
      height: 100%;
      overflow: hidden;
      z-index: 0;
    }

    &:hover {
      transform: scale(1.05, 1.05);
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
      box-shadow: 0 18px 17px -18px rgba(0, 0, 0, 0.5);
    }

    .maskup, .maskdown {
      position: absolute;
      background-image: url("/assets/img/mask.svg?v");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: auto 100%;
      width: 100%;
      height: 27.5%;
    }
    .maskup {
      background-position: top right;
      top: -2.2px;
      right: -2.2px;
    }
    .maskdown {
      background-position: bottom left;
      bottom: -2.2px;
      left: -2.2px;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      position: absolute;
      color: white;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      text-align: left;
      padding-top: 8%;
      padding-left: 15px;

      .title, .subtitle {
        margin: 0;
      }

      .title {
        font-size: 9px;
        font-weight: 800;
        margin-top: 7px;
        text-transform: uppercase;
        width: 83%;
        line-height: 1.25em;
        text-align: left;

        @media only screen and (min-width: 720px) {
          font-size: 10px;
          width: 93%;
        }

        @media only screen and (min-width: 7992x) {
          font-size: 11px;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 15px;
        }
      }

      .subtitle {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
      }

      img {
        opacity: 0.8;
      }
    }
  }
}
