
.short-animate {
  transition: .5s ease-in-out;
}

.long-animate {
  transition: .5s .5s ease-in-out;
}

.lightbox-wrapper, .lightbox-wrapper_pf {
  color: white;
  position: fixed;
  top: -100vh;
  bottom: 100vh;
  left: 0;
  right: 0;
  z-index: 889 !important;
  opacity: 0;
  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top: 0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width: 50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height: 50px;
    }
  }
  .lightbox-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(0);

    overflow-x: scroll;
    padding-bottom: 0;

    .container {
      top: 2em;
      padding-bottom: 2em;
      position: absolute;

      @media only screen and (min-width: 515px) {
        top: 7em;
        padding-bottom: 7em;
      }
    }

    img.imgportfolio {
      width: 100%;
      margin-top: 0;

      @media only screen and (min-width: 768px) {
        margin-top: 90px !important;
      }
    }

    h2 {
      font-size: 26px;
      margin-bottom: 0;

      @media only screen and (min-width: $mobileBreakpoint) {
        font-size: 36px;
      }
    }

    h5 {
      font-size: 2rem !important;
      font-weight: 300;
      margin-top: 10px;
    }

    p {
      font-size: 14px;
      line-height: 1.3em;

      @media only screen and (min-width: $mobileBreakpoint) {
        font-size: 16px;
      }
    }

    h2, p {
      color: $WhiteBackground;
    }

    @media only screen and (min-width: 768px) {
      padding-bottom: 60px;
    }
  }
  a {
    color: white;
  }

  a.button.button-primary, button.button.button-primary {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 128px;
    padding: 1.8rem 3rem;
    text-decoration: none;
    letter-spacing: .02em;
    line-height: 1.25em;
    margin: 20px;

    &:hover {
      color: $darkColorBackground;
      background-color: $primaryColorReverse !important;
    }
  }

  .direction {
    position: absolute;
    bottom: 0;
    margin: 30px 0 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    z-index: 2;
    width: 100%;

    span {
      text-decoration: none;
      font-weight: 600;
      margin: 0 12px;
    }

    a {
      padding: 0;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      margin: 5em 0;

      a {
        padding: 10px 60px;
      }
    }
  }
}

.lightbox-wrapper {
  background: $lightboxCTAbackground;
}

.lightbox-wrapper_pf {
  position: fixed;
  top: -100vh;
  bottom: 100vh;
  left: 0;
  right: 0;
  background: $lightboxPFbackground;
  z-index: 501;
  opacity: 0;
  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top: 0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width: 50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height: 50px;
    }
  }
  .lightbox-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(0);
  }
  a {
    color: white;
  }
}

#lightbox-controls {
  position: fixed;
  height: 70px;
  width: 70px;
  top: -70px;
  right: 0;
  z-index: 890 !important;
  background: rgba(0, 0, 0, .1);
}

#close-lightbox {
  display: block;
  position: absolute;
  overflow: hidden;
  height: 50px;
  width: 50px;
  text-indent: -5000px;
  right: 10px;
  top: 10px;
  transform: rotate(45deg);
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    background: white;
    border-radius: 2px;
  }
  &:before {
    height: 0px;
    width: 3px;
    left: 24px;
    top: 0;
    transition: .5s .5s ease-in-out;
  }
  &:after {
    width: 0px;
    height: 3px;
    top: 24px;
    left: 0;
    transition: .5s 1s ease-in-out;
  }
}
.flexAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media only screen and (min-width: $mobileBreakpoint) {
    flex-direction: row;
  }
}

.lightboxedMenu {
  width: 90% !important;
  max-width: 500px !important;

  &.services {
    max-width: 1000px !important;
  }

  @media only screen and (min-width: $mobileBreakpoint) {
    width: 50% !important;

    &.services {
      width: 80% !important;
    }
  }

  hr {
    margin-top: 50px;
  }

  h4.title {
    margin: 8px 0 12px 0;
    color: $generalFontColor;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
    padding: 10px 16px;
    z-index: 1;
    box-shadow: 7px 7px 14px rgba(0,0,0,0.4);
    display: inline-block;
    left: -8px;
    position: relative;
    border-radius: 9px 3px 9px 3px;
    line-height: 1.3em;

    @media only screen and (min-width: $mobileBreakpoint) {
      font-size: 1.65em;
    }
  }

  h3.subtitle {
    margin: 8px 0 12px 0;
    transform: skewY(-4deg);
    line-height: 1.3em;
  }

  .top-title {
    position: relative;
    color: white;
    font-size: 1.5em;
    font-weight: 800;
    width: 100%;
    text-transform: uppercase;
    padding: 18px 0 0 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.3em;
    margin-top: 25px;

    &:before {
      content: "";
      width: 100%;
      height: 70px;
      background-image: url("/assets/img/title_section_icon_wh.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 50%;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      font-size: 3em;
    }
  }
  .mid-title {
    position: relative;
    color: white;
    text-transform: uppercase;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: justify;

    &:after {
      content: "";
      width: 100%;
      height: 70px;
      background-image: url("/assets/img/title_section_icon_wh.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 50%;
    }

    p {
      line-height: 1.4em;
    }

    span {
      //margin: 7px 0;
      display: block;
    }
    @media only screen and (min-width: $mobileBreakpoint) {
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
      border-left: 1px inset white;
      padding: 7px 12px;
      background-color: rgba(255,255,255,0.15);
      left: 0;
      margin-bottom: 3px;
      position: relative;
      //clip-path: polygon(0 0, 97% 0,100% 15%,100% 100%,0% 100%,0 95%);

      strong {
        text-transform: uppercase;
        font-size: 1.1em;
      }

      &:nth-child(2n+1) {
        background-color: rgba(255,255,255,0.25);
        left: 0;
      }
    }
    @media only screen and (min-width: $mobileBreakpoint) {
      margin-bottom: 50px;
    }
  }
}
